if (process.env.NODE_ENV === 'development') {
    // load config
}

if (process.env.NODE_ENV === 'production') {
    // load config
}

const nodeEnvironment = process.env.NODE_ENV;
const environment = process.env.ENV ? process.env.ENV.trim() : 'foobar';
const language = process.env.LANG ? process.env.LANG.trim() : 'da';
const domain = process.env.DOMAIN ? process.env.DOMAIN.trim() : 'localhost';

const name =
    domain === 'localhost' ? 'Localhost' :
        domain === 'happyflight' ? 'Happyflight.dk' :
            domain === 'rejseforsinkelse' ? 'Rejseforsinkelse.dk' :
                '';
let sentryDsn =
    domain === 'localhost' ? 'https://XYZ.ingest.sentry.io/XYZ' :
        domain === 'happyflight' ? 'https://eb0cc5c1b6af6308fef540daf5b672dd@o351370.ingest.sentry.io/4506021978177536' :
            domain === 'rejseforsinkelse' ? 'https://49cf4ea9b79a406ea9eb4d2c618d4e48@o351370.ingest.sentry.io/5378471' :
                '';
let smartlook =
    domain === 'localhost' ? 'SMXRTLOOK' :
        domain === 'happyflight' ? 'SMXRTLOOK' :
            domain === 'rejseforsinkelse' ? '088e706e3237942e13d918883399d8aeaa02ec7f' :
                '';

let siteMetadata = {
    environment: environment,
    nodeEnvironment: nodeEnvironment,
    language: language,
    name: name
};

module.exports = {
    siteMetadata: siteMetadata,
    flags: {
        DEV_SSR: true
    },
    plugins: [
        'gatsby-plugin-postcss',
        {
            resolve: "@sentry/gatsby",
            options: {
                dsn: sentryDsn
            }
        },
        {
            resolve: `gatsby-plugin-smartlook-tracking`,
            options: {
                includeInDevelopment: false,
                id: smartlook,
            },
        },
        'gatsby-plugin-image',
        'gatsby-plugin-react-helmet',
        'gatsby-plugin-sharp',
        'gatsby-transformer-sharp',
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'images',
                path: './src/images/',
            },
            __key: 'images',
        },
        'gatsby-plugin-sass',
        'gatsby-plugin-fontawesome-css',
    ],
};
