import i18n from 'i18next';
import da from './da.json';
import en from './en.json';

const config = require('../gatsby-config');

const resources = {
  da: {
    translation: {
      ...da,
    }
  },
  en: {
    translation: {
      ...en,
    }
  },
};

i18n.init({
  resources: resources,
  lng: config.siteMetadata.language,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;